/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExtractionData, ExtractionRaw } from '@facephi/sdk-web';
import {
  IconWeight,
  IconName,
  InphiniteThemeBaseColors,
  InphiniteThemeColors,
} from '@facephi/ui-react';
import { GovernmentValidationDto } from './governmentValidation';

export enum ResultsScreenType {
  detail = 'detail',
  reduced = 'reduced',
}

export enum MimeType {
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  png = 'image/png',
  mp4 = 'video/mp4',
  pdf = 'application/pdf',
}

export type Asset = {
  type: string;
  url: string;
  contentType: MimeType;
  source?: string;
};

export enum StatusTypeList {
  BLACKLISTED = 'BLACKLISTED',
  ACTIVE = 'ACTIVATED',
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  STARTED = 'STARTED',
  NOT_ACTIVE = 'NOT_ACTIVATED',
  PENDENT = 'PENDING',
  DENIED = 'DENIED',
  SUCCEEDED = 'SUCCEEDED',
  VALIDATE = 'VALIDATED',
  READ_ONLY = 'READ_ONLY',
  SAVED = 'SAVED',
  CANCELLED = 'CANCELLED',
}

export enum StatusOperations {
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  BLACKLISTED = 'BLACKLISTED',
}

export enum FacialAuthenticationStatus {
  none = 'None',
  negative = 'Negative',
  uncertain = 'Uncertain',
  positive = 'Positive',
  pose = 'None_Because_Pose_Exceed',
  invalidExtractions = 'None_Because_Invalid_Extractions',
}

type tInfo = {
  message: string;
  iconName: IconName;
  iconColor: InphiniteThemeBaseColors;
  iconWeight: IconWeight;
};

export type IconInfo = {
  iconName: IconName;
  iconColor: InphiniteThemeColors;
};

export const infoFacial: { [key in string]: tInfo } = {
  match_external: {
    message: 'The person in the picture and the governmentService match',
    iconName: 'CheckCircle',
    iconColor: 'success',
    iconWeight: 'fill',
  },
  notMatch_external: {
    message: 'The person in the picture and the governmentService dont match',
    iconName: 'XCircle',
    iconColor: 'error',
    iconWeight: 'fill',
  },
  match: {
    message: 'The person on the document and the selfie match',
    iconName: 'CheckCircle',
    iconColor: 'success',
    iconWeight: 'fill',
  },
  notMatch: {
    message: 'The person on the document and the selfie dont match',
    iconName: 'XCircle',
    iconColor: 'error',
    iconWeight: 'fill',
  },
  expired: {
    message: 'The process expired due to user inactivity.',
    iconName: 'WarningCircle',
    iconColor: 'warning',
    iconWeight: 'fill',
  },
  cancelled: {
    message: 'The process was cancelled by the user.',
    iconName: 'WarningCircle',
    iconColor: 'warning',
    iconWeight: 'fill',
  },
  failure: {
    message:
      'We have not been able to verify. The photo may have some reflection or low resolution',
    iconName: 'WarningCircle',
    iconColor: 'warning',
    iconWeight: 'fill',
  },
};

export const governmentInfoFacial: { [key in string]: tInfo } = {
  match: {
    message: 'The person in the picture and the governmentService match',
    iconName: 'CheckCircle',
    iconColor: 'success',
    iconWeight: 'fill',
  },
  notMatch: {
    message: 'The person in the picture and the governmentService dont match',
    iconName: 'XCircle',
    iconColor: 'error',
    iconWeight: 'fill',
  },
};

export const circleIconData: { [key in string]: IconInfo } = {
  success: {
    iconName: 'CheckCircle',
    iconColor: 'success400',
  },
  error: {
    iconName: 'XCircle',
    iconColor: 'error400',
  },
  warning: {
    iconName: 'WarningCircle',
    iconColor: 'warning400',
  },
};

export const statusIconData: { [key in string]: IconInfo } = {
  success: {
    iconName: 'Check',
    iconColor: 'success400',
  },
  error: {
    iconName: 'X',
    iconColor: 'error400',
  },
  warning: {
    iconName: 'WarningCircle',
    iconColor: 'warning400',
  },
};

export enum TrackingFamily {
  AUTHENTICATION = 'AUTHENTICATION',
  ONBOARDING = 'ONBOARDING',
}

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export enum FacialLivenessDiagnostic {
  none = 'None',
  spoof = 'Spoof',
  uncertain = 'Uncertain',
  live = 'Live',
  badQuality = 'NoneBecauseBadQuality',
  faceToClose = 'NoneBecauseFaceTooClose',
  faceNotFound = 'NoneBecauseFaceNotFound',
  faceTooSmall = 'NoneBecauseFaceTooSmall',
  angleTooLarge = 'NoneBecauseFaceTooLarge',
  imageDataError = 'NoneBecauseImageDataError',
  internalError = 'NoneBecauseInternalError',
  imagePreProcess = 'NoneBecauseImagePreprocessError',
  tooManyFaces = 'NoneBecauseTooManyFaces',
  tooCloseBorder = 'NoneBecauseFaceTooCloseToBorder',
  faceCroped = 'NoneBecauseFaceCropped',
  licenseError = 'NoneBecauseLicenseError',
  faceOccluded = 'NoneBecauseFaceOccluded',
}

export type DeviceInformation = {
  type?: string;
  model: string;
  osVersion: string;
  brand: string;
  deviceId?: string;
};

export enum OperationResultReason {
  selphidInternalError = 'SELPHID_INTERNAL_ERROR',
  selphidCancelByUser = 'SELPHID_CANCEL_BY_USER',
  selphidTimeout = 'SELPHID_TIMEOUT',
  selphiInternalError = 'SELPHI_INTERNAL_ERROR',
  selphiCancelByUser = 'SELPHI_CANCEL_BY_USER',
  selphiTimeout = 'SELPHI_TIMEOUT',
  phingersInternalError = 'PHINGERS_INTERNAL_ERROR',
  phingersCancelByUser = 'PHINGERS_CANCEL_BY_USER',
  phingersYimeout = 'PHINGERS_TIMEOUT',
  agoraInternalError = 'AGORA_INTERNAL_ERROR',
  agoraCancelByUser = 'AGORA_CANCEL_BY_USER',
  sdkMobileError = 'SDK_MOBILE_ERROR',
  documentValidationNotassed = 'DOCUMENT_VALIDATION_NOT_PASSED',
  documentValidationError = 'DOCUMENT_VALIDATION_ERROR',
  facialAuthenticationNotPassed = 'FACIAL_AUTHENTICATION_NOT_PASSED',
  facialLivenessNotPassed = 'FACIAL_LIVENESS_NOT_PASSED',
  facialAuthenticationError = 'FACIAL_AUTHENTICATION_ERROR',
  facialLivenessError = 'FACIAL_LIVENESS_ERROR',
}

export type OperationResult = {
  type: string;
  status: StatusOperations;
  reason: OperationResultReason;
};

export type OperationResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  operation: Operation;
};

export type DataFields = {
  key: string;
  value: string;
};

export type PersonalInformation = {
  name: string;
  surname: string;
  nationality: string;
};
export type OcrData = {
  documentImages: Asset[];
  dataMap: object;
  personalInformation?: PersonalInformation;
  source: string;
  default?: boolean;
};

export type ManualSecurity = {
  scanReference: string;
  timestamp: string;
  document: {
    type: string;
    issuingCountry: string;
    status: string;
    dob: string;
    expiry: string;
    firstName: string;
    gender: string;
    idSubtype: string;
    lastName: string;
    nationality: string;
    number: string;
  };
  transaction: {
    date: string;
    merchantScanReference: string;
    source: string;
    status: string;
    updatedAt: string;
  };
  verification: {
    mrzCheck: string;
    rejectReason?: {
      rejectReasonCode: string;
      rejectReasonDescription: string;
    };
  };
};

export type AutomaticSecurity = {
  decision?: string;
  quality?: string;
  fta?: string;
};

export type Security = {
  source: string;
  succeed: boolean;
  data: Record<string, unknown>;
};

export type Operation = {
  family?: TrackingFamily;
  operationId?: string;
  lastUpdateTime?: string;
  creationTime?: string;
  customerId?: string;
  steps?: string[];
  status?: StatusOperations;
  currentStep?: string;
  stepsFlow?: string[];
  coordinates?: Coordinates;
  assets?: Asset[];
  facialAuthenticateResult?: FacialAuthenticateResult[];
  facialAuthenticateResultList?: FacialAuthenticateResult[];
  facialLivenessResult?: {
    diagnostic: FacialLivenessDiagnostic;
    description: string;
  };
  manualSecurity?: ManualSecurity;
  automaticSecurity?: AutomaticSecurity;
  ocrData?: OcrData[];
  device?: DeviceInformation;
  deleted?: boolean;
  results?: OperationResult[];
  ocrDataList?: OcrData[];
  securityInfoDataList?: GovernmentValidationDto[] | [];
};

export type FacialAuthenticateResult = {
  similarity: number;
  authStatus: FacialAuthenticationStatus;
  source: string;
  assetsUrl: string[] | [];
};

export enum VerificationStatusValues {
  verified = 'APPROVED_VERIFIED',
  done = 'DONE',
  error = 'ERROR',
}

export const ocrDataGovKeys = {
  countryCode: 'COUNTRY_CODE',
  backNationality: 'BACKSIDE / MRZ_DATA / NATIONALITY',
  backIssuingCountry: 'BACKSIDE / MRZ_DATA / ISSUING_COUNTRY',
  frontSideCountry: 'FRONTSIDE / MRZ_DATA / ISSUING_COUNTRY',
  backSex: 'BACKSIDE / MRZ_DATA / SEX',
  frontSex: 'FRONTSIDE / MRZ_DATA / SEX',
  backDocumentId: 'BACKSIDE / MRZ_DATA / IDENTITY_NUMBER',
  frontDocumentId: 'FRONTSIDE / MRZ_DATA / IDENTITY_NUMBER',
};

export enum GovernmentCountryCodes {
  ARG = 'ARG',
  PER = 'PER',
  BRA = 'BRA',
  ZAF = 'ZAF'
};

export type ExtractedDocumentsData = {
  extractionData: ExtractionData;
  extractionRaw: ExtractionRaw;
};

export type UncroppedImages = {
  frontFull: any;
  backFull: any;
};

export const internalProviders = {
  sdkMobile: 'sdk-mobile',
  demoBackend: 'demo-backend',
};

export const external = 'external';
