import { FlexContainer, StatusType } from '@facephi/ui-react';
import { StatusOperations } from '../../states';
import { CardResultsHeaderTitle, CardResultsStyles } from './Styles';

type Props = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  status?: StatusType | StatusOperations;
  icon?: React.ReactNode;
  dropdown?: React.ReactNode;
  testId?: string;
  idTitle?: string;
};

export const CardResults = ({
  title,
  children,
  className,
  status,
  icon,
  dropdown,
  testId,
  idTitle,
}: Props) => {
  return (
    <CardResultsStyles className={className} status={status} testId={testId}>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <CardResultsHeaderTitle
          id={idTitle || `id-${title}`}
          testId={idTitle || `id-${title}`}
        >
          {title}
        </CardResultsHeaderTitle>
        {dropdown && <>{dropdown}</>}
        {icon ? icon : null}
      </FlexContainer>

      {children && <>{children}</>}
    </CardResultsStyles>
  );
};
